import axios from "axios";

const getAxiosInstance = () => {
  return axios.create({
    headers: { Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}` },
    baseURL: "https://085-0250225.io/strapi/api",
  });
};

export const getInitialStep = async () => {
  const result = await getAxiosInstance().get("global", {
    params: { populate: "*" },
  });
  return result.data?.data?.attributes?.initial_step?.data?.id;
};

export const getStep = async (id: number) => {
  const result = await getAxiosInstance().get(`steps/${id}`, {
    params: {
      "populate[0]": "next_steps",
      "populate[1]": "next_steps.step",
      "populate[2]": "audio",
    },
  });
  if (!result || !result.data || !result.data.data) return;
  const step: Step = {
    audio: `/strapi${result.data.data.attributes.audio.data.attributes.url}`,
    id: result.data.data.id,
    title: result.data.data.attributes.title,
    nextSteps: result.data.data.attributes.next_steps.map((step: any) => ({
      key: step.key,
      step: step.step.data.id,
    })),
  };
  return step;
};
