import { useEffect, useRef, useState } from "react";
import "./App.css";
import { Keyboard } from "./components/Keyboard";
import { Player } from "./components/Player";
import banner from "./images/banner.png";
import ndnmk from "./images/ndnmk.png";
import sword from "./images/sword.png";
import { getInitialStep, getStep } from "./utils/api";

function App() {
  const [currentStep, setCurrentStep] = useState<null | Step>(null);
  // I don't understand why but we have to use this ref or otherwise currentStep will be null when we need it
  const currentStepRef = useRef<null | Step>(null);
  const [start, setStart] = useState(false);

  useEffect(() => {
    if (!start) return;
    getInitialStep().then((stepId) => {
      getAndSetStep(stepId);
    });
  }, [start]);

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  const getAndSetStep = (stepId: number) => {
    if (!stepId) return;
    getStep(stepId).then((step) => {
      if (!step) return;
      setCurrentStep(step);
    });
  };

  const handleKeyEntered = (key: string) => {
    if (!currentStepRef.current) return;
    const step = currentStepRef.current.nextSteps?.find(
      (step) => step.key === key
    );
    if (!step) return;
    getAndSetStep(step.step);
  };

  if (!start) {
    return (
      <div className="center">
        <button onClick={() => setStart(true)}>start</button>
      </div>
    );
  }

  return (
    <>
      <Player src={currentStep?.audio} />
      <div className="container">
        <img className="banner" src={banner} alt="Angel Line 1M" />
        <Keyboard onKeyEntered={handleKeyEntered} />
        <img className="sword" src={sword} alt="NDNMK Solutions" />
        <img className="ndnmk" src={ndnmk} alt="NDNMK Solutions" />
      </div>
    </>
  );
}

export default App;
