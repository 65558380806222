import {
  FC,
  HTMLProps,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import ImageMapper from "react-img-mapper";
import Keys from "../images/keys.png";

const ASPECT_RATIO = 809 / 584;

export const Keyboard: FC<
  { onKeyEntered: (key: string) => void } & HTMLProps<HTMLDivElement>
> = ({ onKeyEntered, className }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState(0);

  const calculateAndSetParentWidth = () => {
    if (containerRef.current?.clientHeight) {
      const height = containerRef.current?.clientHeight;
      const width = containerRef.current?.clientWidth;
      if (width / height > ASPECT_RATIO) {
        setParentWidth(height * ASPECT_RATIO);
      } else {
        setParentWidth(width);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => calculateAndSetParentWidth();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => calculateAndSetParentWidth(), []);

  return (
    <div className={`keys ${className}`} ref={containerRef}>
      <ImageMapper
        src={Keys}
        onClick={(e) => {
          if (typeof e.id !== "string") return;
          onKeyEntered(e.id);
        }}
        responsive={true}
        parentWidth={parentWidth}
        map={{
          name: "keys",
          areas: [
            {
              id: "1",
              shape: "poly",
              active: false,
              coords: [215, 130, 340, 130, 340, 220, 215, 220],
            },
            {
              id: "2",
              shape: "poly",
              active: false,
              coords: [345, 130, 470, 130, 470, 220, 345, 220],
            },
            {
              id: "3",
              shape: "poly",
              active: false,
              coords: [460, 130, 580, 130, 580, 220, 460, 220],
            },
            {
              id: "4",
              shape: "poly",
              active: false,
              coords: [180, 235, 330, 235, 330, 360, 180, 360],
            },
            {
              id: "5",
              shape: "poly",
              active: false,
              coords: [345, 235, 470, 235, 470, 360, 345, 360],
            },
            {
              id: "6",
              shape: "poly",
              active: false,
              coords: [480, 235, 620, 235, 620, 360, 480, 360],
            },
            {
              id: "7",
              shape: "poly",
              active: false,
              coords: [110, 370, 310, 370, 310, 550, 110, 550],
            },
            {
              id: "8",
              shape: "poly",
              active: false,
              coords: [310, 370, 500, 370, 500, 550, 310, 550],
            },
            {
              id: "9",
              shape: "poly",
              active: false,
              coords: [500, 370, 690, 370, 690, 550, 500, 550],
            },
          ],
        }}
      />
    </div>
  );
};
